import { Box, Breadcrumbs as MuiBreadcrumbs, Link, Typography } from '@mui/material';
import { useScreenSize } from 'utils/hooks/useScreenSize';
import { NavLink, useMatches } from 'react-router-dom';
import { ReactElement } from 'react';

export const Breadcrumbs = () => {
  const { smScreenOrUp } = useScreenSize();

  const matches = useMatches();

  const matchesWithCrumb = matches.filter((match) => {
    const handle = match.handle as { crumb: (data: unknown) => ReactElement | null };
    return !!handle?.crumb;
  });

  return (
    <MuiBreadcrumbs
      separator='›'
      aria-label='breadcrumbs'
      sx={{
        color: 'white',
        pl: {
          xs: 2,
          sm: 0,
        },
      }}
    >
      {matchesWithCrumb.map((match, idx) => {
        const handle = match.handle as { crumb: (data: unknown) => ReactElement | null };

        const crumb = handle.crumb(match.data);

        const isCurrent = idx === matchesWithCrumb.length - 1;

        return (
          <Box key={match.pathname}>
            {isCurrent ? (
              <Typography
                role='navigation'
                aria-label='breadcrumb previous location'
                variant='body1'
                textOverflow={{ xs: 'ellipsis', sm: 'unset' }}
                whiteSpace='nowrap'
                maxWidth={{ xs: 160, sm: 'unset' }}
                overflow='hidden'
              >
                {crumb}
              </Typography>
            ) : (
              <Link
                component={NavLink}
                to={match.pathname}
                sx={{
                  color: '#fff',
                  opacity: 0.4,
                }}
                role='navigation'
                aria-label='breadcrumb location'
              >
                {smScreenOrUp ? crumb : '...'}
              </Link>
            )}
          </Box>
        );
      })}
    </MuiBreadcrumbs>
  );
};
